


















import { Component, Vue } from 'vue-property-decorator'
import FormBase from '@/shared/classes/form/form-base'
import Field from '@/shared/classes/form/field'
import { FieldType } from '@/shared/configs/field-type.config'
import { Routes } from '@/shared/router'
import AuthLayout from '@/layouts/AuthLayout.vue'
import Form from '@/components/form/Form.vue'

@Component({
  components: { AuthLayout, Form }
})
export default class RemindPassword extends Vue {
  Routes = Routes
  success = false

  form: FormBase = new FormBase()
    .setEndpoint('password/send')
    .setFields([
      new Field()
        .setType(FieldType.email)
        .setKey('email')
        .setTitle('Email')
    ])
    .setInjectValues({
      url: this.$router.resolve({
        name: Routes.resetPassword,
        params: {
          token: ':token',
          email: ':email',
        }
      }).href
    })
    .setSubmit({
      text: 'Remind password',
      classes: 'd-flex justify-content-center align-items-center w-100 button-full-width larger-button'
    })
    .setOnSuccess(this.onSuccess)

  onSuccess() {
    this.success = true
  }
}
